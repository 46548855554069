import React, {useCallback, useRef} from 'react';

import styles from './InstallAppPopup.module.scss';
import buttonClose from '../../../assets/images/button-close.png';
import installAppSteps from '../../../assets/images/InstallAppSteps.png'


import useClickOutside from "../../../hooks/useClickOutside";

const InstallAppPopup = ({ deviceType, setInstallAppPopupOpen }) => {
    const isFirstRender = useRef(true);

	const wrapperRef = useClickOutside(useCallback(() => {
		setInstallAppPopupOpen()
    }, []));

	const closePopup = (event) => {
		if (event.target === event.currentTarget){
			setInstallAppPopupOpen(false);
		}
	};

    return (
        <div className={styles.overlay} onClick={(event) => closePopup(event)}>
            <div className={styles.wrapper}>
                <div className={styles.innerWrapper}>
                    <div className={styles.crateContains}>Add Siege: World War Il web shop to your home page</div>
					<div>
						<img src={installAppSteps} alt='img' className={styles.installSteps}></img>
					</div>
                </div>
                <img src={buttonClose} alt={'img'} className={styles.buttonClose} onClick={() => setInstallAppPopupOpen(false)} />
            </div>
        </div>
    );
};

export default InstallAppPopup;