import React from 'react';
import defalutBackground from '../../assets/images/gambit/background-2.png';
import plusSign from '../../assets/images/gambit/plus.png'
import styles from './Gambit.module.scss';
import WarToken from '../../assets/images/war_token.png';
import defalutToken from '../../assets/images/gambit/token-2.png'
import defalutTicket from '../../assets/images/gambit/ticket-2.png'
import { formatNumber } from '../../shared/helpers';

const Gambit = ({
    TicketsAmount,
    TokensAmount,
    EventType,
    OfferId,
    Type,
    selectOfferById,
    currency,
    OldPrice,
    NewPrice,
    config,
    WarTokens,
    isReviewOrder
}) => {
    const getDynamicTokenImage = (type) => {
        try {
            return require(`../../assets/images/gambit/token-${type}.png`).default;
        } catch (error) {
            return  defalutToken;
        }
    };
    const getDynamicTicketImage = (type) => {
        try {
            return require(`../../assets/images/gambit/ticket-${type}.png`).default;
        } catch (error) {
            return defalutTicket;
        }
    };
    const getDynamicBackground = (type) => {
        try {
            return require(`../../assets/images/gambit/background-${type}.png`).default;
        } catch (error) {
            return defalutBackground;
        }
    };
    const onOfferClick = () => {
        if (!selectOfferById) {
            return;
        }
        selectOfferById(OfferId, Type);
    };
    return (
        <div 
            className={`${styles.wrapper} ${!isReviewOrder ? styles.darkBackground : ''}`}
            onClick={() => onOfferClick()}
        >
            <div className={styles.innerWrapper}>
                <img src={getDynamicBackground(EventType)} alt={''} className={styles.background} />
                <div className={styles.contentContainer}>
                    <div className={styles.contentTopContainer}>
                        <div className={styles.itemContainer}>
                            <div className={styles.name}>{config[`ticketTitle${EventType}`]}</div>
                            <div className={styles.quantity}>{TicketsAmount}</div>
                            <img className={styles.itemImgae} src={getDynamicTicketImage(EventType)} alt={''}/>
                        </div>
                        <img className={styles.plusSign} src={plusSign} alt={''}/>
                        <div className={styles.itemContainer}>
                            <div className={styles.name}>{config[`tokenTitle${EventType}`]}</div>
                            <div className={styles.quantity}>{TokensAmount}</div>
                            <img className={styles.itemImgae} src={getDynamicTokenImage(EventType)} alt={''}/>
                        </div>
                    </div>  
                    {/* TODO: Refactor this into a separate component for better reusability and maintainability */}
                   { !isReviewOrder && <div> 
                        <div className={styles.pricesWrapper}>
                            <div className={styles.oldPrice}>
                                {OldPrice.toFixed(2)} {currency}
                                <div className={'stroke'}></div>
                            </div>
                            <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
                        </div>
                    </div> }
                </div>
            </div>
            {/* TODO: Refactor this into a separate component for better reusability and maintainability */}
            { !isReviewOrder && <div className={styles.warTokensWrapper}>
                <div>+&nbsp;</div>
                <div>{formatNumber(WarTokens)}</div>
                <img className={styles.warTokenImg} src={WarToken} alt=''/>
            </div> }
        </div>
    );
};

export default Gambit;