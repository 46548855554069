import { EVENTS_IMAGES } from './constants';

export const formatEventPassOffers = (offers) => {
    return (offers || []).map((offer) => {
        return {
            ...offer,
            ImageURL: EVENTS_IMAGES[offer?.EventType] || '',
        };
    });
};

export const formatNumber = (number) => {
    let formattedNumber = number.toLocaleString();
    formattedNumber = formattedNumber.replace(/,/g, " ");

    return formattedNumber;
};

export const verifyMilestoneHashAndGetId = (hash) => {
    const regex = /#milestone(\d+)/;
    const match = hash.match(regex);
    if (match[0]) {
        const regex = /^#/;
        return match[0].replace(regex, "");
    } else {
        return null;
    }
}

export const hasMilestonesToClaimLeft = (milestones) => {
    return milestones.some((milestone) => {
        return !milestone.Claimed && (milestone.CollectedWarTokens >= milestone.RequiredTokens);
    });
}

/**
 * Standart validation
 * @param {string} email
 * @returns boolean
 */
export const validateEmail = (email) => {
    if (!email.trim().length) {
        return null;
    }

    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        );
};

export const getDeviceType = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
	if (/android/i.test(userAgent)) {
	  return 'android';
	}
  
	if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
	  return 'ios';
	}
  
	return 'unknown';
  };

  // determine the launch context 
  export const isStandaloneApp = () => {
	return 	window.matchMedia('(display-mode: standalone)').matches ||
			window.navigator.standalone ||
			document.referrer.includes('android-app://');
  }