import React, { useState, useEffect, useCallback, createContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MOBILE_VIEW_BREAKPOINT } from './shared/constants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import MainLayout from "./Layouts/MainLayout";
import Login from "./pages/Login/Login";
import Shop from "./pages/Shop/Shop";
import Checkout from "./pages/Checkout/Checkout";
import NotFound from "./pages/NotFound/NotFound";
import FindId from "./pages/FindId/FindId";
import CheckoutResult from "./pages/CheckoutResult/CheckoutResult";
import {mainConfig} from "./shared/mainConfig";
import Milestones from "./pages/Milestones/Milestones";
import { isSupported } from "firebase/messaging";
import InstallAppPopup  from './components/InstallApp/InstallAppPopup/InstallAppPopup.js';
import { isStandaloneApp } from './shared/helpers.js';

export const MilestonesContext = createContext(null);

function App() {
    const [config, setConfig] = useState(mainConfig[localStorage.getItem('siegeShopLanguage')] || mainConfig.EN);
    const [windowSize, setWindowSize] = useState({});
    const [isLogged, setIsLogged] = useState(false);
    const [player, setPlayer] = useState({});
    const [offers, setOffers] = useState({});
    const [selectedOffer, setSelectedOffer] = useState({});
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('siegeShopLanguage') || 'EN');
    const [hasAvailableMilestones, setHasAvailableMilestones] = useState(false);
    const [isMilestoneDescriptionClosed, setIsMilestoneDescriptionClosed] = useState(false);
    const [hasUnclaimedMilestones, setHasUnclaimedMilestones] = useState(false);
    const [isMarketingEmailGiven, setIsMarketingEmailGiven] = useState(null);
	const [installAppPopupOpen, setInstallAppPopupOpen] = useState(false);
	const [isStandalone, setIsStandalone] = useState(false);


	useEffect(() => {
		if (isLogged){
			(async () => {
				const hasFirebaseMessagingSupport = await isSupported();
				if (hasFirebaseMessagingSupport) {
					const { requestForToken } = await import("./firebase/firebaseConfig.js");
					await requestForToken();
				}
			})();
		}
	  }, [isLogged]);

    const loginComponent = <Login
        setPlayer={setPlayer}
        isLogged={isLogged}
        setIsLogged={setIsLogged}
        setOffers={setOffers}
        config={config}
        windowSize={windowSize}
        setIsMarketingEmailGiven={setIsMarketingEmailGiven}
    />

    const handleResize = useCallback(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
            isMobileView: window.innerWidth <= MOBILE_VIEW_BREAKPOINT
        });
    }, []);

    const updateUnclaimedMilestones = (hasMilestones) => {
        setHasUnclaimedMilestones(hasMilestones);
    }

    useEffect(() => {
        handleResize();

        window.addEventListener('resize', handleResize, false);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [handleResize]);

    useEffect(() => {
        const newLanguage = localStorage.getItem('siegeShopLanguage') || currentLanguage;
        setConfig(mainConfig[newLanguage]);
    }, [currentLanguage]);

    useEffect(() => {
        let storedPlayer = localStorage.getItem('playerInfo');
        storedPlayer = JSON.parse(storedPlayer);
        if (!isLogged && storedPlayer && storedPlayer.id && storedPlayer.name) {
            setIsLogged(true);
            setPlayer(storedPlayer);
        }
        if (isLogged && !storedPlayer ) {
            setIsLogged(false);
        }
    });

	useEffect(() => {
		const checkStandalone = () => {
		  const standalone = isStandaloneApp();
		  setIsStandalone(standalone);
		};
	
		checkStandalone();
	
	  }, []);

    return (
        <BrowserRouter>
            <ToastContainer position="top-left" theme="dark" limit={3} />
            <MilestonesContext.Provider value={{
                hasAvailableMilestones,
                setHasAvailableMilestones,
                isMilestoneDescriptionClosed,
                setIsMilestoneDescriptionClosed
            }}>
                <Header
                    currentLanguage={currentLanguage}
                    setCurrentLanguage={setCurrentLanguage}
                    windowSize={windowSize}
                    config={config}
                    player={player}
                    setIsLogged={setIsLogged}
                    isLogged={isLogged}
                    hasUnclaimedMilestones={hasUnclaimedMilestones}
					setInstallAppPopupOpen={setInstallAppPopupOpen}
					isStandaloneContext={isStandalone}
                />
                <MainLayout>
                    <Routes>
                        <Route path={'/'} element={loginComponent} />
                            <Route index element={loginComponent} />
                            <Route path={'find-id'} element={<FindId config={config} />} />
                            <Route path={'shop'} element={
                                <Shop
                                    windowSize={windowSize}
                                    offers={offers}
                                    setSelectedOffer={setSelectedOffer}
                                    setIsLogged={setIsLogged}
                                    config={config}
                                    setPlayer={setPlayer}
                                    updateUnclaimedMilestones={updateUnclaimedMilestones}
                                    setHasUnclaimedMilestones={setHasUnclaimedMilestones}
                                    _isMarketingEmailGiven={isMarketingEmailGiven}
                                />
                                }
                            />
                            <Route path={'checkout'} element={<Checkout config={config} windowSize={windowSize} />} />
                            <Route path={'checkout-result'} element={<CheckoutResult config={config} windowSize={windowSize} />} />
                            <Route path={'milestones'} element={
                                <Milestones
                                    config={config}
                                    windowSize={windowSize}
                                    setIsLogged={setIsLogged}
                                    setHasUnclaimedMilestones={setHasUnclaimedMilestones}
                                    />
                                }
                            />
                            { /* 404 */ }
                            <Route path={'*'} element={<NotFound config={config} />} />
                    </Routes>
					{installAppPopupOpen ? <InstallAppPopup setInstallAppPopupOpen={setInstallAppPopupOpen}></InstallAppPopup> : null}
                </MainLayout>
                {window.location.pathname === '/' ?
                    null
                :
                    <Footer config={config} windowSize={windowSize} />
                }
            </MilestonesContext.Provider>
        </BrowserRouter>
    );
}

export default App;
