import React from 'react';
import styles from './OfferInfo.module.scss';
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";
import {formatNumber, validateEmail} from '../../shared/helpers';



const OfferInfo = ({
  Type,
  OldPrice,
  NewPrice,
  currency,
  WarTokens,
  WarToken,
  removeOffer,
  isMobileView,
  removeButtonText,
}) => {
  return (
    <>
      <div className={styles.info}>
        <div className={styles.offerType}>{Type}</div>
        {OldPrice !== NewPrice && (
          <div className={styles.oldPrice}>
            <div className="stroke"></div>
            {OldPrice.toFixed(2)} {currency}
          </div>
        )}
        <div className={styles.newPrice}>
          {NewPrice.toFixed(2)} {currency}
        </div>
        <div className="warTokensWrapper">
          <div>+&nbsp;</div>
          <div>{formatNumber(WarTokens)}</div>
          <img className="warTokenImg" src={WarToken} alt="" />
        </div>
        {isMobileView ? (
          <ButtonNew
            text={removeButtonText}
            isSmall={true}
            onClick={removeOffer}
            isNegative={true}
          />
        ) : null}
      </div>
      {!isMobileView && (
        <div className={styles.buttonRemove}>
          <ButtonNew
            text={removeButtonText}
            isSmall={false}
            onClick={removeOffer}
            isNegative={true}
          />
        </div>
      )}
    </>
  );
};

export default OfferInfo;
