import React from 'react';
import styles from '../GemsPackage/GemsPackage.module.scss';
import background from '../../assets/images/fortune/background.png';
import token from '../../assets/images/fortune/tokens.png';
import WarToken from '../../assets/images/war_token.png';
import { formatNumber } from '../../shared/helpers';

const FortuneEventTokens = ({
    OldPrice,
    NewPrice,
    OfferId,
    Type,
    selectOfferById,
    currency,
    amount,
    config,
    WarTokens
    
}) => {

    return (
        <div className={`${styles.wrapper} ${styles.fortuneEventWrapper}`} onClick={() => selectOfferById(OfferId, Type, token,config.fortuneEventTokens, amount, Type, null, background)}>
            <img src={background} alt={''} className={styles.background} />
            <div className={styles.name}>{config.fortuneEventTokens}</div>
            <div className={styles.quantity}>{amount}</div>
            <img src={token} alt={''} className={styles.fortuneImage} />
            <div className={styles.pricesWrapper}>
                <div className={styles.oldPrice}>
                    {OldPrice.toFixed(2)} {currency}
                    <div className={'stroke'}></div>
                </div>
                <div className={styles.newPrice}>{NewPrice.toFixed(2)} {currency}</div>
            </div>
            <div className="warTokensWrapper">
                <div>+&nbsp;</div>
                <div>{formatNumber(WarTokens)}</div>
                <img className="warTokenImg" src={WarToken} alt='' />
            </div>
        </div>
    );
};

export default FortuneEventTokens;