import React, { useState, useEffect, useRef } from "react";

import styles from "./InstallAppButton.module.scss";
import { ReactComponent as BookmarkBadge } from "../../../assets/images/bookmark-badge.svg";
import { getDeviceType } from '../../../shared/helpers.js';

/*
 * This component is responsible for showing the install app popup Button
 * The logic needs to be here cos of browser specific behavior requiring user gesture to show the install prompt
 * For ios devices, we show the install app popup button
*/
const InstallAppButton = ({windowSize, setInstallAppPopupOpen}) => {
	let addBtn = useRef(null);
	let deferredPrompt = useRef(null);
	const [isVisible, setIsVisible] = useState(true);
	let isEventHadled = useRef(false);
	const buttonBrakePoint = 1300;

	const handleUserGesture = async () => {
		if (!deferredPrompt.current) return;

		// Show the prompt
		deferredPrompt.current.prompt();

		// Clear the stored deferredPrompt
		deferredPrompt.current = null;
		setIsVisible(false);
		isEventHadled.current = true;
	};

	useEffect(() => {
		// we show the steps to install the app only on ios devices
		if (getDeviceType() === 'ios') {
			setIsVisible(true);
			addBtn.current.addEventListener("click", () => {
				setInstallAppPopupOpen(true)
				return;
			})
		}
		const beforeInstallPrompt = (e) => {
			if (isEventHadled.current) {
				setIsVisible(false);
				return;
			}
			e.preventDefault();
			// Stash the event so it can be triggered later.
			deferredPrompt.current = e;
			setIsVisible(true);
			// here we use event listener so we can capture the beforeInstallPrompt and attach it to the button
			addBtn.current.addEventListener("click", () => {
				handleUserGesture();
			});
		};

		window.addEventListener("beforeinstallprompt", beforeInstallPrompt);

		return () => {
		window.removeEventListener("beforeinstallprompt", beforeInstallPrompt);
		};
	}, []);

	return (
		<>
			<div
				ref={addBtn}
				className={styles.installAppButton}
				style={{ display: isVisible ? "block" : "none" }}
			>
				<div className={styles.bookmarkBadge}>
					<BookmarkBadge />
					{windowSize.width <= buttonBrakePoint ? 'Get' : 'Install Shop App'}
				</div>
			</div>
		</>
	);
};

export default InstallAppButton;