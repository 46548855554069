import React, {useState, useEffect, useCallback, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import styles from './ShopReviewOrder.module.scss';
import gemsImgBackground from '../../assets/images/gems/shop_gems_background.png';
import ArrowGoBack from '../../assets/images/arrow_go_back.png';
import WarToken from '../../assets/images/war_token.png';
import MarketingCampainBanner from '../../assets/images/marketing_campain_banner.png';

import {ENV, OFFER_TYPE_TO_NAME, WEBSITE_URL_TERMS} from "../../shared/constants";
import {CSSTransition} from "react-transition-group";
import ButtonNew from "../../components/buttons/ButtonNew/ButtonNew";
import {formatNumber, validateEmail} from '../../shared/helpers';

import Gambit from '../../components/Gambing/Gambit';
import { OFFER_TYPES } from '../../shared/constants';
import OfferInfo from './OfferInfo';

const ShopReviewOrder = ({
    OldPrice,
    NewPrice,
    Type,
    ImageURL,
    mappedImg,
    mappedName,
    mappedQuantity,
    setSelectedOffer,
    config,
    windowSize,
    currency,
    WarTokens,
    imgBackground,
    IsMarketingEmailGiven,
    selectedOffer
}) => {
    const navigate = useNavigate();
    let isFirstPageView = useRef(true);
    const [isAgreed, setIsAgreed] = useState(false);
    const [isAgreedForMarketingEmail, setIsAgreedForMarketingEmail] = useState(false);
    const [doesWantReceipt, setDoesWantReceipt] = useState(false);
    const [emailInput, setEmailInput] = useState('');

    let Image = null;
    if (ImageURL) {
        Image = <img src={ImageURL} alt={''} className={styles.offerImg} />
    } else {
        Image = <div className={styles.gemsImgWrapper}>
            <img src={imgBackground || gemsImgBackground} slt={''} className={styles.imgBackground} />
            <div className={styles.name}>{mappedName}</div>
            <div className={styles.quantity}>{mappedQuantity && mappedQuantity.toLocaleString()}</div>
            <img src={mappedImg} alt={''} className={`${styles.gemsImage} ${OFFER_TYPE_TO_NAME[Type]}`} />
        </div>
    }
    const handleRemoveOffer = () => setSelectedOffer(null);

    const proceed = () => {
        if (!isAgreed) {
            toast.error(config.notAgreedWithTermsAndCond);
            return;
        }
        if ((doesWantReceipt || isAgreedForMarketingEmail) && !validateEmail(emailInput)) {
            toast.error(config.invalidEmail);
            return;
        }

        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);

        const updatePlayerInfo = { ...player };
        updatePlayerInfo.email = emailInput;
        localStorage.setItem(
            'playerInfo',
            JSON.stringify(updatePlayerInfo)
        );

        navigate('/checkout', { state: {
            saveEmailAgreed: IsMarketingEmailGiven ? null : isAgreedForMarketingEmail,
            wantsReceipt: doesWantReceipt,
            email: emailInput.length > 0 ? emailInput : null,
        }});
    }

    const handleGoBack = useCallback((event) => {
        if (event) {
            event.preventDefault();
            setSelectedOffer(null);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);

        let player = localStorage.getItem('playerInfo');
        player = JSON.parse(player);

        if (player && player.email) {
            setEmailInput(player.email);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('popstate', (event) => handleGoBack(event), false);

        return () => {
            window.removeEventListener('popstate', handleGoBack);
        }
    }, []);

    useEffect(() => {
        if (isFirstPageView.current) {
            if (ENV === 'live') {
                window.gtag('event', 'screen_view', {
                    'app_name': 'Siege shop',
                    'screen_name': 'page_review_order'
                });
                window.gtag('event', 'page_view', {
                    'app_name': 'Siege shop',
                    'page_title': 'Review Order'
                });
            } else {
                window.gtag('event', 'screen_view', {
                    'app_name': 'DEV Siege shop',
                    'screen_name': 'DEV page_review_order'
                });
                window.gtag('event', 'page_view', {
                    'app_name': 'DEV Siege shop',
                    'page_title': 'DEV Review Order'
                });
            }
            isFirstPageView.current = false;
        }
    }, []);

    return (
        <div className={styles.wrapper}>
            <CSSTransition in appear timeout={300} classNames={'fade-in-slide-down'}>
                <div className={styles.titleWrapper}>
                    <img className={styles.arrowGoBack} src={ArrowGoBack} alt={''}
                         onClick={() => setSelectedOffer(null)}/>
                    <div className={styles.title}>{config.reviewOrder}</div>
                </div>
            </CSSTransition>
            <div className={'dividerHorizontal'}></div>
           { 
            OFFER_TYPES.gambitLeaguePackages !== Type ?
            <div className={styles.offerInfo}>
                {Image}
                <OfferInfo
                    Type={OFFER_TYPE_TO_NAME[Type]}
                    OldPrice={OldPrice}
                    NewPrice={NewPrice}
                    currency={currency}
                    WarTokens={WarTokens}
                    WarToken={WarToken}
                    removeOffer={handleRemoveOffer}
                    isMobileView={windowSize.isMobileView}
                    removeButtonText={config.remove}
                />
            </div>
            :
            <div className={`${styles.offerInfo} ${styles.offerDobuleItemInfo}`}>
                <Gambit {...selectedOffer} currency={currency} config={config}  isReviewOrder={true}/>
                <OfferInfo
                    Type={OFFER_TYPE_TO_NAME[Type]}
                    OldPrice={OldPrice}
                    NewPrice={NewPrice}
                    currency={currency}
                    WarTokens={WarTokens}
                    WarToken={WarToken}
                    removeOffer={handleRemoveOffer}
                    isMobileView={windowSize.isMobileView}
                    removeButtonText={config.remove}
                />
            </div>
           }
            <div className={'dividerHorizontal'}></div>
            <div className={styles.discountWrapper}>
                <div className={styles.discountInfo}>
                    <div className={styles.names}>
                        <div>{config.item}</div>
                        {OldPrice !== NewPrice ?
                            <div>{config.discount}</div>
                            :
                            null
                        }
                    </div>
                    <div className={styles.values}>
                        <div>{OldPrice.toFixed(2)} {currency}</div>
                        {(OldPrice - NewPrice) !== 0 ?
                            <div>-{(OldPrice - NewPrice).toFixed(2)} {currency}</div>
                            :
                            null
                        }
                    </div>
                </div>
                <div className={'dividerHorizontal'}></div>
            </div>
            <div className={styles.total}>{config.orderTotal}&nbsp;&nbsp;&nbsp;{NewPrice.toFixed(2)} {currency}</div>
            <div className={styles.wrapperUserInteractions}>
                <div className={styles.agree} onClick={() => setIsAgreed(!isAgreed)}>
                    <input type='checkbox' checked={isAgreed}/>
                    <a href={WEBSITE_URL_TERMS} className={styles.terms}><span>{config.agreeWithTermsAndCond}</span></a>
                </div>
                {IsMarketingEmailGiven ?
                    null
                :
                    <>
                        <div className={styles.promoBannerMarketing}>
                            <img src={MarketingCampainBanner} alt={''}/>
                            <span>{config.marketingEmailBanner}</span>
                        </div>
                        <div className={styles.wrapperEmailMarketing}>
                            <div className={styles.agree}
                                 onClick={() => setIsAgreedForMarketingEmail(!isAgreedForMarketingEmail)}>
                                <input type='checkbox' checked={isAgreedForMarketingEmail}/>
                                <span className={styles.terms}>{config.receiveMarketingEmails}</span>
                            </div>
                        </div>
                    </>
                }
                <div className={styles.wrapperEmailReceipt}>
                    <div className={styles.agree} onClick={() => setDoesWantReceipt(!doesWantReceipt)}>
                        <input type='checkbox' checked={doesWantReceipt}/>
                        <span className={styles.terms}>{config.wantReceipt}</span>
                    </div>
                    {doesWantReceipt ||  isAgreedForMarketingEmail ?
                        <input
                            placeholder={config.enterEmail}
                            className={styles.enterEmail}
                            value={emailInput}
                            type="email"
                            autoComplete="email"
                            name="email"
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                    :
                        null
                    }
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <ButtonNew text={config.proceedToCheckout} isDisabled={!isAgreed} onClick={proceed}/>
            </div>
        </div>
    );
};

export default ShopReviewOrder;