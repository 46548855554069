import {
    WEBSITE_URL,
    WEBSITE_URL_NEWS,
    WEBSITE_URL_GALLERY,
    WEBSITE_URL_COMMUNITY,
    WEBSITE_URL_FAQ,
    WEBSITE_URL_SUPPORT,
} from './constants';

export const mainConfig = {
    EN: {
        menus: [
            { name: 'shop', url: '/shop', isShop: true, isHighlighted: false },
            { name: 'milestones', url: '/milestones', isShop: true, isHighlighted: false, isSpecial: true },
            { name: 'news', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'gallery', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'community', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'shop',
        loginInfo:
            'This shop is only supported for IOS players. Android players must use only the in game due to Google Play policy.',
        signWithId: 'Sign in using your personal ID:',
        idPlaceHolder: 'enter your ID here ...',
        proceed: 'proceed',
        problemFindingId: 'Problem finding your ID?',
        errors: {
            WENT_WRONG: 'Something went wrong.',
            ANDROID_PLAYER:
                'This id is associated with an android device.\n This service is available only for iOS users.',
            PLAYER_NOT_FOUND:
                'This id is not associated with any player.\n Please try again or read below how to find your id.',
            EMPTY_INPUT: 'Please enter a ID',
            BANNED_USER: 'This ID is associated with a banned player. Please reach out to our support team.',
        },
        logout: 'logout',
        webShopExclusiveOffers: 'Web Shop Exclusive Offers',
        eventPassOffers: 'Event Pass',
        specialOffers: 'Special Offers',
        everyDayOffers: 'Every Day Offers',
        gems: 'Gems',
        crates: 'Crates',
        resources: 'Resources',
        fortuneEventTokens: 'Special Events',
        gambitLeaguePackages: 'Special Events',
        fortuneEventTokensTitle: 'Fortune Event Tokens',
        ticketTitle2: 'Souls',
        tokenTitle2: 'Pumpkins',
        ticketTitle3: 'Event Tickets',
        tokenTitle3: 'Cookies',
        currency: 'USD',
        reviewOrder: 'Review Order',
        remove: 'remove',
        item: 'item',
        discount: 'discount',
        orderTotal: 'Order Total:',
        checkout: 'Checkout',
        backToShop: 'Back To Shop',
        proceedToCheckout: 'proceed to checkout',
        notFound: {
            error: 'Error 404!',
            noSuchPage: 'The page you were looking for does not exist.',
        },
        checkoutResult: {
            successTitle: 'Your order has been executed!',
            successInfo:
                'You will receive the content in your in-game inbox.\n Please note that this may take several minutes.',
            eventPassSuccessInfo:
                'Your purchase will be available in-game shortly.\n Please note that this may take several minutes.',
            errorTitle: 'There was an error\n while processing your order.',
            errorInfo: 'Please try again or contact our team at:',
        },
        findIdTitle: 'Problem finding your ID?',
        findIdStep1: '1. Click on your name at the Camp in the game.',
        findIdStep2:
            '2. Your ID is located below your name in the Player profile screen.',
        backToLogin: 'back to login',
        offerEnds: 'Offer ends in:',
        agreeWithTermsAndCond:
            'I agree with the Terms and Conditions and the\n Refund and Cancellation policy.',
        privacyPolicy: 'Privacy Policy  ',
        termsAndConditions: 'Terms of Use',
        faq: 'faq',
        support: 'support',
        filter: 'Filter',
        filterClose: 'Apply and Close',
        filterErr: 'You cannot disable all of the categories.',
        resourcesNames: {
            paper: 'Paper',
            coal: 'Coal',
            metal: 'Metal',
            ammo: 'Ammunition',
            scrap: 'Scrap',
            basicManual: 'Basic Manual',
            tacticsGuide: 'Tactics Guide',
        },
        cratesNames: {
            captains: 'Captains Crate',
            majors: 'Majors Crate',
            colonels: 'Colonels Crate',
            combat: 'Combat Crate',
            elite: 'Elite Crate',
            legendary: 'Legendary Crate',
        },
        crateContains: 'This crate contains:',
        resourcesContent: 'Resources content:',
        perksContent: 'Perks content:',
        intelContent: 'Intel content:',
        common: 'common',
        uncommon: 'uncommon',
        rare: 'rare',
        heroic: 'heroic',
        gemsPackages: [
            'Handful of Gems',
            'Sack of Gems',
            'Chest of Gems',
            'Barrel of Gems',
            'Vault of Gems',
            'Mountain of Gems',
        ],
        freeOffers: 'Free Offers',
        free: 'FREE',
        freeOffersCheckout: {
            successTitle: 'Your free reward awaits you in the game! ',
        },
        allCategories: 'All categories',
        closeCategories: 'Close categories',
        login: 'login',
        havingQuestions: 'Having questions about the shop?',
        getWarTokens: 'Get War Badges',
        warTokensDesc: 'for every purchase in the web shop. \n Claim rewards for each Milestone reached.',
        milestones: 'Milestones',
        milestone: 'Milestone',
        claim: 'claim',
        claimed: 'claimed',
        reward: 'reward',
        noMilestones: 'There are no Milestones',
        milestoneClaimSuccess: 'You have successfully claimed the reward from the milestone! You will receive the content in your in-game inbox.',
        milestoneClaimFail: 'There was an error. Please try again or contact our team.',
        commanderResources: {
            experience: 'Experience',
            crates: 'Commander\'s crates',
        },
        commanderOffers: 'Commander Offers',
        commanders: 'Commanders',
        receiveMarketingEmails: '(Optional) I agree to receive news and marketing materials on my email.',
        wantReceipt: '(Optional) I require a receipt sent to me on my email.',
        marketingEmailBanner: 'Get 25% off your next purchase with your email subscription!',
        enterEmail: 'Enter email',
        invalidEmail: 'The email you entered is not valid.',
        notAgreedWithTermsAndCond: 'You have to agree with the Terms and Conditions.',
    },
    ES: {
        menus: [
            { name: 'tienda', url: '/shop', isShop: true, isHighlighted: false },
            { name: 'objetivos', url: '/milestones', isShop: true, isHighlighted: false, isSpecial: true },
            { name: 'noticias', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'galería', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'comunidad', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'soporte', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'tienda',
        loginInfo:
            'Esta tienda sólo se soporta para jugadores de iOS. Los jugadores de Android deben usar sólo la disponible en el juego, debido a la política de Google Play.',
        signWithId: 'Inicia sesión utilizando tu ID personal:',
        idPlaceHolder: 'introduce tu ID aquí...',
        proceed: 'continuar',
        problemFindingId: '¿Tienes dificultades para encontrar tu ID?',
        errors: {
            WENT_WRONG: 'Algo fue mal.',
            ANDROID_PLAYER:
                'Este id está asociado a un dispositivo Android.\n Este servicio está disponible sólo para usuarios de iOS.',
            PLAYER_NOT_FOUND:
                'Este id no está asociado a ningún jugador.\n Por favor, inténtalo de nuevo o lee abajo cómo encontrar tu id.',
            EMPTY_INPUT: 'Por favor, introduce un ID',
            BANNED_USER: 'Esta ID está asociada a un jugador baneado. Ponte en contacto con nuestro equipo de asistencia.',
        },
        logout: 'cerrar sesión',
        webShopExclusiveOffers: 'Ofertas exclusivas en la Tienda Online',
        eventPassOffers: 'Abono para el evento',
        specialOffers: 'Ofertas especiales',
        everyDayOffers: 'Ofertas diarias',
        gems: 'Gemas',
        crates: 'Cofres',
        fortuneEventTokens: 'Eventos Especiales',
        gambitLeaguePackages: 'Eventos Especiales',
        fortuneEventTokensTitle: 'Tokens de Evento de Fortuna',
        ticketTitle2: 'Almas',
        tokenTitle2: 'Calabazas',
        ticketTitle3: 'Entradas para eventos',
        tokenTitle3: 'Galletas',
        resources: 'Recursos',
        currency: 'USD',
        reviewOrder: 'Revisar pedido',
        remove: 'eliminar',
        item: 'artículo',
        discount: 'descuento',
        orderTotal: 'Pedido total:',
        checkout: 'Pago',
        backToShop: 'Volver a la tienda',
        proceedToCheckout: 'proceder a la compra',
        notFound: {
            error: '¡Error 404!',
            noSuchPage: 'La página que estás buscando no existe.',
        },
        checkoutResult: {
            successTitle: '¡Tu pedido fue ejecutado!',
            successInfo:
                'Recibirá el contenido en tu bandeja de entrada en el juego.\n Por favor, ten en cuenta que esto puede tardar unos minutos.',
            eventPassSuccessInfo:
                'Su compra estará disponible en el juego pronto.\n Por favor, ten en cuenta que esto puede tardar unos minutos.',
            errorTitle:
                'Se produjo un error\n durante el procesamiento del pedido.',
            errorInfo:
                'Por favor, inténtalo de nuevo o contacta con nuestro equipo en:',
        },
        findIdTitle: '¿Tienes dificultades para encontrar tu ID?',
        findIdStep1: '1. Haz clic sobre tu nombre en el Campo en el juego.',
        findIdStep2:
            '2. Tu ID se encuentra debajo de tu nombre en la pantalla del perfil de jugador.',
        backToLogin: 'volver al inicio de sesión',
        offerEnds: 'La oferta expira en:',
        agreeWithTermsAndCond:
            'Estoy de acuerdo con los Términos y Condiciones\n y la Política de cancelación y devolución ',
        privacyPolicy: 'Política de privacidad',
        termsAndConditions: 'Términos y Condiciones',
        faq: 'faq',
        support: 'soporte',
        filter: 'Filtro',
        filterClose: 'Aplicar y cerrar',
        filterErr: 'No puede deshabilitar todas las categorías.',
        resourcesNames: {
            paper: 'Papel',
            coal: 'Carbón',
            metal: 'Metal',
            ammo: 'Municiones',
            scrap: 'Chatarra',
            basicManual: 'Basic Manual',
            tacticsGuide: 'Tactics Guide',
        },
        cratesNames: {
            captains: 'Cofre del capitán',
            majors: 'Cofre del comandante',
            colonels: 'Cofre del coronel',
            combat: 'Combat Crate',
            elite: 'Elite Crate',
            legendary: 'Legendary Crate',
        },
        crateContains: 'Este cofre contiene:',
        resourcesContent: 'Contenido de recursos:',
        perksContent: 'Contenido de ventajas:',
        intelContent: 'Intel content:',
        common: 'Común',
        uncommon: 'Poco común',
        rare: 'Rara',
        heroic: 'Heroica',
        gemsPackages: [
            'Puñado de gemas',
            'Costal de gemas',
            'Cofre de gemas',
            'Barril de gemas',
            'Bóveda de gemas',
            'Montaña de gemas',
        ],
        freeOffers: 'Ofertas gratuitas',
        free: 'GRATUITO',
        freeOffersCheckout: {
            successTitle: '¡Tu premio gratuito te está esperando en el juego!',
        },
        allCategories: 'Todas las categorías',
        closeCategories: 'Cerrar categorías',
        login: 'entrar',
        havingQuestions: 'Tienes preguntas sobre la tienda?',
        getWarTokens: 'Consigue insignias militares',
        warTokensDesc: 'por cada compra en la tienda web. \n Recibe recompensas por cada hito alcanzado.',
        milestones: 'Hitos',
        milestone: 'Hito',
        claim: 'recibir',
        claimed: 'recibida',
        reward: 'recompensa',
        noMilestones: 'No hay hitos',
        milestoneClaimSuccess: '¡Has recibido con éxito la recompensa del hito! Recibirás el contenido en tu bandeja de entrada dentro del juego.',
        milestoneClaimFail: 'Hubo un error. Inténtalo de nuevo o ponte en contacto con nuestro equipo.',
        commanderResources: {
            experience: 'Experience',
            crates: 'Commander\'s crates',
        },
        commanderOffers: 'Commander Offers',
        commanders: 'Commanders',
        receiveMarketingEmails: '(Optional) I agree to receive news and marketing materials on my email.',
        wantReceipt: '(Optional) I require a receipt sent to me on my email.',
        marketingEmailBanner: 'Get 25% off your next purchase with your email subscription!',
        enterEmail: 'Enter email',
        invalidEmail: 'The email you entered is not valid.',
        notAgreedWithTermsAndCond: 'You have to agree with the Terms and Conditions.',
    },
    DE: {
        menus: [
            { name: 'shop', url: '/shop', isShop: true, isHighlighted: false },
            { name: 'Ziele', url: '/milestones', isShop: true, isHighlighted: false, isSpecial: true },
            { name: 'news', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'Galerie', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'Community', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'Support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'shop',
        loginInfo:
            'Dieser Shop wird nur für iOS-Spieler unterstützt. Android-Spieler müssen aufgrund der Google Play-Richtlinien nur den Shop im Spiel verwenden.',
        signWithId: 'Mit deiner persönlichen ID anmelden:',
        idPlaceHolder: 'Hier deine ID eingeben ...',
        proceed: 'Fortfahren',
        problemFindingId: 'Probleme, deine ID zu finden?',
        errors: {
            WENT_WRONG: 'Etwas ist schief gelaufen.',
            ANDROID_PLAYER:
                'Diese ID ist mit einem Android-Gerät verlinkt.\n Dieser Dienst ist nur für iOS-User verfügbar.',
            PLAYER_NOT_FOUND:
                'Diese ID ist mit keinem Spieler verbunden.\n Bitte probiere es noch einmal oder lies unten, wie du deine ID findest.',
            EMPTY_INPUT: 'Bitte eine ID eingeben',
            BANNED_USER: 'Diese ID ist mit einem gesperrten Spieler verknüpft. Bitte wenden Sie sich an unser Support-Team.',
        },
        logout: 'Abmelden',
        webShopExclusiveOffers: 'Web Shop Exclusive Offers',
        eventPassOffers: 'Event-Pass',
        specialOffers: 'Sonderangebote',
        everyDayOffers: 'Tägliche Angebote',
        gems: 'Juwelen',
        crates: 'Kisten',
        fortuneEventTokens: 'Spezielle Ereignisse',
        gambitLeaguePackages: 'Spezielle Ereignisse',
        fortuneEventTokensTitle: 'Glücksevent-Tokens',
        ticketTitle2: 'Seelen',
        tokenTitle2: 'Kürbisse',
        ticketTitle3: 'Veranstaltungstickets',
        tokenTitle3: 'Cookies',
        resources: 'Ressourcen',
        currency: 'USD',
        reviewOrder: 'Bestellung prüfen',
        remove: 'entfernen',
        item: 'Gegenstand',
        discount: 'Discount',
        orderTotal: 'Bestellsumme',
        checkout: 'Zur Kasse',
        backToShop: 'Zurück zum Shop',
        proceedToCheckout: 'Weiter zur Kasse',
        notFound: {
            error: 'Error 404!',
            noSuchPage: 'Die Seite, die du suchst, existiert nicht.',
        },
        checkoutResult: {
            successTitle: 'Deine Bestellung wurde ausgeführt!',
            successInfo:
                'Du erhältst den Inhalt in deinem Postfach im Spiel.\n Bitte beachte, dass dies einige Minuten dauern kann.',
            eventPassSuccessInfo:
                'Ihr Kauf wird bald im Spiel verfügbar sein.\n Bitte beachte, dass dies einige Minuten dauern kann.',
            errorTitle:
                'Bei der Bearbeitung deiner Bestellung ist ein Fehler aufgetreten.',
            errorInfo:
                'Bitte probiere es erneut oder kontaktiere unser Team unter:',
        },
        findIdTitle: 'Probleme, deine ID zu finden?',
        findIdStep1: '1. Klicke im Spiel auf deinen Namen im Lager.',
        findIdStep2:
            '2. Deine ID befindet sich unter deinem Namen auf dem Spielerprofil-Bildschirm.',
        backToLogin: 'zurück zum Login',
        offerEnds: 'Angebot endet in:',
        agreeWithTermsAndCond:
            'Ich bin mit den Allgemeinen Bedingungen\n und den Erstattungs-und Stornierungsbedingungen einverstanden.',
        privacyPolicy: 'Datenschutzpolitik',
        termsAndConditions: 'Nutzungsbedingungen',
        faq: 'faq',
        support: 'support',
        filter: 'Filter',
        filterClose: 'Anwenden und Schließen',
        filterErr: 'Du kannst nicht alle Kategorien deaktivieren.',
        resourcesNames: {
            paper: 'Papier',
            coal: 'Kohle',
            metal: 'Metall',
            ammo: 'Munition',
            scrap: 'Abfälle',
            basicManual: 'Basic Manual',
            tacticsGuide: 'Tactics Guide',
        },
        cratesNames: {
            captains: 'Kapitän-Kiste',
            majors: 'Major-Kiste',
            colonels: 'Oberst-Kiste',
            combat: 'Combat Crate',
            elite: 'Elite Crate',
            legendary: 'Legendary Crate',
        },
        crateContains: 'Diese Kiste enthält:',
        resourcesContent: 'Inhalt der Ressource:',
        perksContent: 'Inhalt der Vorteile:',
        intelContent: 'Intel content:',
        common: 'Gewöhnlich',
        uncommon: 'Besonders',
        rare: 'Selten',
        heroic: 'Heroisch',
        gemsPackages: [
            'Hand voll Juwelen',
            'Sack voll Juwelen',
            'Kiste voll Juwelen',
            'Fass voll Juwelen',
            'Tresor voll Juwelen',
            'Juwelenberg',
        ],
        freeOffers: 'Kostenlose Angebote',
        free: 'KOSTENLOS',
        freeOffersCheckout: {
            successTitle:
                'Deine kostenlose Belohnung wartet auf dich im Spiel!',
        },
        allCategories: 'Alle Kategorien',
        closeCategories: 'Kategorien schließen',
        login: 'login',
        havingQuestions: 'Fragen zum Shop?',
        getWarTokens: 'Erhalte Kriegsabzeichen',
        warTokensDesc: 'für jeden Einkauf im Webshop. \n Erhalte Belohnungen für jeden erreichten Meilenstein.',
        milestones: 'Meilensteine',
        milestone: 'Meilenstein',
        claim: 'Abholen',
        claimed: 'Abgeholt',
        reward: 'Belohnung',
        noMilestones: 'Es gibt keine Meilensteine',
        milestoneClaimSuccess: 'Du hast die Belohnung für den Meilenstein erfolgreich abgeholt! Du erhältst den Inhalt in deinem Posteingang im Spiel.',
        milestoneClaimFail: 'Es ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere unser Team.',
        commanderResources: {
            experience: 'Experience',
            crates: 'Commander\'s crates',
        },
        commanderOffers: 'Commander Offers',
        commanders: 'Commanders',
        receiveMarketingEmails: '(Optional) I agree to receive news and marketing materials on my email.',
        wantReceipt: '(Optional) I require a receipt sent to me on my email.',
        marketingEmailBanner: 'Get 25% off your next purchase with your email subscription!',
        enterEmail: 'Enter email',
        invalidEmail: 'The email you entered is not valid.',
        notAgreedWithTermsAndCond: 'You have to agree with the Terms and Conditions.',
    },
    FR: {
        menus: [
            { name: 'magasin', url: '/shop', isShop: true, isHighlighted: false },
            { name: 'jalons', url: '/milestones', isShop: true, isHighlighted: false, isSpecial: true },
            { name: 'nouveautés', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'galerie', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'communauté', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'magasin',
        loginInfo:
            "Le magasin n'est disponible que pour les joueurs iOS. Les joueurs Android ne doivent utiliser que dans le jeu à cause de la politique de Google play.",
        signWithId: 'Connectez-vous avec votre ID :',
        idPlaceHolder: 'entrez votre ID ici ...',
        proceed: 'procéder',
        problemFindingId: 'Problème pour trouver votre ID?',
        errors: {
            WENT_WRONG: "Une erreur s'est produite.",
            ANDROID_PLAYER:
                "L'ID est lié à un appareil android.\n Ce service n'est disponible que pour des joueurs iOS.",
            PLAYER_NOT_FOUND:
                "L'ID n'est lié à aucun joueur.\n Veuillez essayer de nouveau ou lisez ci-dessous comment trouver votre ID",
            EMPTY_INPUT: 'Entrez un ID',
            BANNED_USER: 'Cet ID est lié à un joueur banni. Veuillez contacter notre équipe de support.',

        },
        logout: 'se déconnecter',
        webShopExclusiveOffers: 'Offres exclusives de la boutique en ligne',
        eventPassOffers: "Pass d'événement",
        specialOffers: 'Offres spéciales',
        everyDayOffers: 'Offres quotidiennes',
        gems: 'Diamants',
        crates: 'Caisses',
        fortuneEventTokens: 'Événements Spéciaux',
        gambitLeaguePackages: 'Spezielle Ereignisse',
        fortuneEventTokensTitle: 'Tokens d\'Événement de Fortune',
        ticketTitle2: 'Âmes',
        tokenTitle2: 'Citrouilles',
        ticketTitle3: 'Billets pour événements',
        tokenTitle3: 'Cookies',
        resources: 'Ressources',
        currency: 'USD',
        reviewOrder: "Réviser l'achat",
        remove: 'supprimer',
        item: 'objet',
        discount: 'réduction',
        orderTotal: 'Montant total :',
        checkout: 'Payer',
        backToShop: 'Vers  le magasin',
        proceedToCheckout: 'procéder au paiement',
        notFound: {
            error: 'Erreur 404!',
            noSuchPage: "La page que vous cherchez n'existe pas.",
        },
        checkoutResult: {
            successTitle: 'Votre commande a été exécutée !',
            successInfo:
                'Vous recevrez le contenu dans la boîte de réception dans le jeu.\n Cela peut prendre quelques minutes.',
            eventPassSuccessInfo:
                'Votre achat sera disponible dans le jeu bientôt.\n Cela peut prendre quelques minutes.',
            errorTitle:
                "Une erreur s'est produite\n lors du traitement de votre commande.",
            errorInfo: 'Essayez de nouveau ou bien contactez notre équipe à :',
        },
        findIdTitle: 'Problème pour trouver votre ID?',
        findIdStep1: '1. Cliquez sur votre nom dans le Camp dans le jeu.',
        findIdStep2:
            "2. Votre ID se trouve au dessous de votre nom sur l'écran Profil du joueur.",
        backToLogin: 'retour à la Connexion',
        offerEnds: "L'offre expire dans :",
        agreeWithTermsAndCond:
            "J'accepte les conditions générales ainsi\n que la politique d'annulation et de remboursement.",
        privacyPolicy: 'Politique de confidentialité',
        termsAndConditions: 'Conditions d\'utilisation',
        faq: 'faq',
        support: 'support',
        filter: 'Filtre',
        filterClose: 'Appliquer et Fermer',
        filterErr: 'Vous ne pouvez pas désactiver toutes les catégories.',
        resourcesNames: {
            paper: 'Papier',
            coal: 'Charbon',
            metal: 'Métal',
            ammo: 'Munition',
            scrap: 'Ferraille',
            basicManual: 'Basic Manual',
            tacticsGuide: 'Tactics Guide',
        },
        cratesNames: {
            captains: 'Caisse du capitaine',
            majors: 'Caisse du major',
            colonels: 'Caisse du colonel',
            combat: 'Combat Crate',
            elite: 'Elite Crate',
            legendary: 'Legendary Crate',
        },
        crateContains: 'Cette caisse contient:',
        resourcesContent: 'Contenu des ressources:',
        perksContent: 'Contenu des avantages:',
        intelContent: 'Intel content:',
        common: 'Ordinaire',
        uncommon: 'Insolite',
        rare: 'Rare',
        heroic: 'Héroïque',
        gemsPackages: [
            'Poignée de diamants',
            'Sac de diamants',
            'Coffre de diamants',
            'Tonneau de diamants',
            'Chambre forte de diamants',
            'Montagne de diamants',
        ],
        freeOffers: 'Offres gratuites',
        free: 'GRATUITEMENT',
        freeOffersCheckout: {
            successTitle: 'Votre récompense gratuite vous attend dans le jeu!',
        },
        allCategories: 'Toutes les catégories',
        closeCategories: 'Fermer les catégories',
        login: 'connexion',
        havingQuestions: 'Vous avez des questions concernant le magasin?',
        getWarTokens: 'Prendre des badges de guerre.',
        warTokensDesc: 'pour chaque achat effectué dans la boutique en ligne. \n Recevez des récompenses pour chaque jalon atteint.',
        milestones: 'Jalons',
        milestone: 'Jalons',
        claim: 'recevoir',
        claimed: 'reçu',
        reward: 'récompense',
        noMilestones: 'Il n\'y a pas de jalons',
        milestoneClaimSuccess: 'Vous avez réussi à recevoir la récompense du jalon! Vous recevrez le contenu dans la Boîte de réception en jeu.',
        milestoneClaimFail: 'Une erreur s\'est produite. Veuillez réessayer ou contacter notre équipe.',
        commanderResources: {
            experience: 'Experience',
            crates: 'Commander\'s crates',
        },
        commanderOffers: 'Commander Offers',
        commanders: 'Commanders',
        receiveMarketingEmails: '(Optional) I agree to receive news and marketing materials on my email.',
        wantReceipt: '(Optional) I require a receipt sent to me on my email.',
        marketingEmailBanner: 'Get 25% off your next purchase with your email subscription!',
        enterEmail: 'Enter email',
        invalidEmail: 'The email you entered is not valid.',
        notAgreedWithTermsAndCond: 'You have to agree with the Terms and Conditions.',
    },
    BR: {
        menus: [
            { name: 'loja', url: '/shop', isShop: true, isHighlighted: false },
            { name: 'etapas', url: '/milestones', isShop: true, isHighlighted: false, isSpecial: true },
            { name: 'notícias', url: WEBSITE_URL_NEWS, isShop: false, isHighlighted: false },
            { name: 'galeria', url: WEBSITE_URL_GALLERY, isShop: false, isHighlighted: false },
            { name: 'comunidade', url: WEBSITE_URL_COMMUNITY, isShop: false, isHighlighted: false },
            { name: 'faq', url: WEBSITE_URL_FAQ, isShop: false, isHighlighted: false },
            { name: 'support', url: WEBSITE_URL_SUPPORT, isShop: false, isHighlighted: false },
        ],
        headerTitle: 'Loja',
        loginInfo:
            'Esta loja é disponível apenas para os jogadores de iOS. Os jogadores de Android devem usar apenas a loja no jogo devido à política do Google Play.',
        signWithId: 'Faça login usando seu ID pessoal:',
        idPlaceHolder: 'insira seu ID aqui ...',
        proceed: 'prosseguir',
        problemFindingId: 'Você está tendo problemas para encontrar seu ID?',
        errors: {
            WENT_WRONG: 'Ocurreu um erro.',
            ANDROID_PLAYER:
                'Este ID está vinculado a um dispositivo Android. Este serviço está disponível apenas para usuários iOS.',
            PLAYER_NOT_FOUND:
                'Este ID não está associado a nenhum jogador. Por favor, tente novamente ou leia abaixo como encontrar o seu ID.',
            EMPTY_INPUT: 'Por favor insira um ID',
            BANNED_USER: 'Este ID está associado a um jogador banido. Entre em contato com nossa equipe de suporte.',
        },
        logout: 'sair',
        webShopExclusiveOffers: 'Ofertas exclusivas da Loja Online',
        eventPassOffers: 'Passe de evento',
        specialOffers: 'Ofertas especiais',
        everyDayOffers: 'Ofertas diárias',
        gems: 'Gemas',
        crates: 'Caixas',
        fortuneEventTokens: 'Eventos Especiais',
        gambitLeaguePackages: 'Eventos Especiais',
        fortuneEventTokensTitle: 'Tokens de Evento de Fortuna',
        ticketTitle2: 'Âmes',
        tokenTitle2: 'Citrouilles',
        ticketTitle3: 'Ingressos para Eventos',
        tokenTitle3: 'Cookies',
        resources: 'Recursos',
        currency: 'USD',
        reviewOrder: 'Revisar o pedido',
        remove: 'remover',
        item: 'objeto',
        discount: 'desconto',
        orderTotal: 'Total da ordem:',
        checkout: 'Checkout',
        backToShop: 'Voltar para a loja',
        proceedToCheckout: 'fazer o checkout',
        notFound: {
            error: 'Erro 404!',
            noSuchPage: 'A pagina que você está procurando não existe.',
        },
        checkoutResult: {
            successTitle: 'Seu pedido foi processado!',
            successInfo:
                'Você receberá o conteúdo na caixa de entrada do jogo.\n Tenha em consideração que isso pode levar vários minutos.',
            eventPassSuccessInfo:
                'Sua compra estará disponível no jogo em breve.\n Tenha em consideração que isso pode levar vários minutos.',
            errorTitle: 'Ocorreu um erro ao processar seu pedido.',
            errorInfo:
                'Por favor, tente novamente ou entre em contato com nossa equipe em:',
        },
        findIdTitle: 'Você está tendo problemas para encontrar seu ID?',
        findIdStep1: '1. Clique no seu nome no Acampamento do jogo.',
        findIdStep2:
            '2. Seu ID está localizado abaixo do seu nome na tela de perfil do jogador.',
        backToLogin: 'Voltar ao login',
        offerEnds: 'A oferta acaba em:',
        agreeWithTermsAndCond:
            'Concordo com os Termos e condições\n de uso e com a Política de cancelamento e devolução.',
        privacyPolicy: 'Política de privacidade',
        termsAndConditions: 'Termos de uso',
        faq: 'faq',
        support: 'support',
        filter: 'Filtro',
        filterClose: 'Aplicar e Fechar',
        filterErr: 'Você não pode desativar todas as categorias.',
        resourcesNames: {
            paper: 'Papel',
            coal: 'Carvão',
            metal: 'Metal',
            ammo: 'Munição',
            scrap: 'Chatarra',
            basicManual: 'Basic Manual',
            tacticsGuide: 'Tactics Guide',
        },
        cratesNames: {
            captains: 'Caixa do Capitão',
            majors: 'Caixa do Major',
            colonels: 'Caixa do Coronel',
            combat: 'Combat Crate',
            elite: 'Elite Crate',
            legendary: 'Legendary Crate',
        },
        crateContains: 'Esta caixa contém:',
        resourcesContent: 'Conteúdo dos recursos:',
        perksContent: 'Conteúdo das vantagens:',
        intelContent: 'Intel content:',
        common: 'Comum',
        uncommon: 'Incomum',
        rare: 'Raro',
        heroic: 'Heroica',
        gemsPackages: [
            'Punhado de gemas',
            'Saco de gemas',
            'Caixa de gemas',
            'Barril de gemas',
            'Arca de gemas',
            'Montes de gemas',
        ],
        freeOffers: 'Ofertas gratuitas',
        free: 'GRATUITO',
        freeOffersCheckout: {
            successTitle: '¡Tu premio gratuito te está esperando en el juego!',
        },
        allCategories: 'Todas as categorias',
        closeCategories: 'Fechar categorias',
        login: 'entrada',
        havingQuestions: 'Tem perguntas sobre a Loja?',
        getWarTokens: 'Obtenha insígnias militares',
        warTokensDesc: 'por cada compra efetuada na loja virtual. \n Receba recompensas por cada Marco alcançado.',
        milestones: 'Marcos',
        milestone: 'Marco',
        claim: 'receber',
        claimed: 'recebida',
        reward: 'recompensa',
        noMilestones: 'Não há Marcos',
        milestoneClaimSuccess: 'Você acaba de receber a recompensa do Marco com sucesso! Encontrará o conteúdo na sua caixa de entrada no jogo..',
        milestoneClaimFail: 'Ocorreu um erro. Por favor, tente mais tarde ou entre em contato com a equipa.',
        commanderResources: {
            experience: 'Experience',
            crates: 'Commander\'s crates',
        },
        commanderOffers: 'Commander Offers',
        commanders: 'Commanders',
        receiveMarketingEmails: '(Optional) I agree to receive news and marketing materials on my email.',
        wantReceipt: '(Optional) I require a receipt sent to me on my email.',
        marketingEmailBanner: 'Get 25% off your next purchase with your email subscription!',
        enterEmail: 'Enter email',
        invalidEmail: 'The email you entered is not valid.',
        notAgreedWithTermsAndCond: 'You have to agree with the Terms and Conditions.',
    },
};

